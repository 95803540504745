<template>
  <div v-if="breadcrumbs?.length" class="layout-container px-0 md:px-56">
    <div class="breadcrumb-wrapper truncate !overflow-x-scroll flex overflow-y-hidden px-24 md:px-0 sm:!overflow-auto items-center">
      <div
        v-for="(item, index) in breadcrumbs"
        :key="index"
        class="breadcrumb text-small-regular text-black flex-shrink-0"
      >
        <GlobalsLinkHelper
          v-if="item.linkUrl"
          :to="item.linkUrl"
        >
          <div class="inline-block align-middle underline animate-all hover:text-black hover:no-underline">
            {{ item.linkText }}
          </div>
          <div class="mx-8 inline-block align-middle">
            <ArrowLongRightIcon
              class="w-16 h-16"
            />
          </div>
        </GlobalsLinkHelper>
        <div v-else class="animate-all text-black mt-2 font-medium">
          {{ item.linkText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ArrowLongRightIcon from '@heroicons/vue/24/outline/esm/ArrowLongRightIcon';
import type { ISimpleLinkItem } from '~/api-types';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContentStore = useGlobalContentStore();
const host = globalContentStore.host;

const props = defineProps<{
  breadcrumbs?: ISimpleLinkItem[];
}>();

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: props.breadcrumbs.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': host + item.linkUrl,
        name: item.linkText,
      },
    };
  }),
};

useJsonld(jsonLd);

/* 
useHead({
  script: [
    {
      type: 'application/ld-json',
      children: JSON.stringify(jsonLd),
    },
  ],
}); */
</script>
<style>
.breadcrumb-wrapper {
  &::-webkit-scrollbar { 
    display: none;
  }
}
</style>
