<template>
  <div class="flex">
    <a
      v-if="currentPage > 1"
      :href="previousPageHref"
      :disabled="currentPage <= 1"
    >Previous</a>
    <div v-for="(page, index) in pages" :key="index">
      <a
        v-if="currentPage != page"
        :href="page == 1 ? route.path : `?page=${page}`"
        class="block mx-6"
      >
        {{ page }}
      </a>
      <div v-else>
        {{ page }}
      </div>
    </div>
    <a
      v-if="currentPage < pages"
      :href="`?page=${currentPage + 1}`"
    >
      Next
    </a>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'nuxt/app';

const props = defineProps({
  pages: Number,
});

const router = useRouter();
const route = useRoute();

const currentPage = ref(parseInt(route.query.page) || 1);

watch(
  () => route.fullPath,
  () => {
    currentPage.value = parseInt(route.query.page) || 1;
  }
);

const previousPageHref = computed(() => {
  if (currentPage.value === 2) {
    return route.path;
  }
  return `?page=${currentPage.value - 1}`;

});
</script>
